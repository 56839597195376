import { init } from '@jill64/sentry-sveltekit-cloudflare/client';

const onError = init(
  'https://b40011bab0abcaff5bcd7989d54fc38f@o4506174305730560.ingest.us.sentry.io/4506174306975744',
  {
    sentryOptions: {
      tracesSampleRate: 0.5,
      replaysSessionSampleRate: 0.3,
      replaysOnErrorSampleRate: 1.0,
      release: __APP_VERSION__ + '-prod'
      // Replay integration enabled in https://github.com/jill64/sentry-sveltekit-cloudflare/blob/main/src/client/init.ts
    }
  }
);

export const handleError = onError((e) => {
  console.error(e);
  return {
    message: e.message
  };
});
